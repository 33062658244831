<template>
  <div class="integrations-page">
    <vuestic-widget :loading="loading">
      <template slot="header">
        <span class="title">Integrations</span>
      </template>
      <div class="row">
        <div class="col-md-12">
          <fieldset class="mt-3">
            <legend>SendGrid</legend>
            <p>SendGrid allows your businesses to send out email to the contacts</p>
            <div v-if="hasSendgridConfig" class="row">
              <div class="col-md-6">
                <text-input name="api_key" v-model="sendgridConfig.api_key" label="Api Key" :disabled="true" />
              </div>
              <div class="col-md-6">
                <text-input name="from_email" v-model="sendgridConfig.options.from_email" label="From Email" :disabled="true" />
              </div>
              <div v-if="!sendgridConfig.options.webhook_id" class="col-md-12 mt-2">
                <div class="text-danger font-weight-bold">
                  <span class="d-block">We could't set webhook because You have reached the maximum number of webhooks per your current billing plan.</span>
                  <span class="d-block">Please be aware that tracking email status won't be available with this integration.</span>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-12 mt-3">
                <button type="button" class="btn btn-sm btn-primary mr-2" @click="onOpenSendgridModal"><i
                    class="fa fa-upload" aria-hidden="true"></i> Set SengGrid Config</button>
                <button v-if="hasSendgridConfig" type="button" class="btn btn-sm btn-danger"
                  @click="onDeleteSendgridModal"><i class="fa fa-trash" aria-hidden="true"></i> Delete</button>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </vuestic-widget>
    <vuestic-modal :isOpen="isOpenModalDelete" @ok="handleDelete" @cancel="isOpenModalDelete = false" okText="Delete"
      cancelText="Cancel" :closeOnOk="false" :processing="processing" okClass="btn btn-danger btn-primary">
      <span slot="title" class="text-danger font-weight-bold">Delete Integration</span>

      <div>Confirm you want to delete this integration?</div>
    </vuestic-modal>
    <vuestic-modal :isOpen="isSendgridModalOpen" :cancelShown="false" :okShown="false" @cancel="closeSendgridModal">
      <span slot="title">Add/Update Your SendGrid Credential</span>
      <ValidationObserver v-slot="{ handleSubmit, invalid, dirty }">
        <form @submit.prevent="handleSubmit(onSendgridUpdate)">
          <div v-if="newIntegration" class="row">
            <div class="col-md-12">
              <text-input name="api_key" v-model="newIntegration.api_key" label="Api Key" validate="required" />
            </div>
            <div class="col-md-12">
              <text-input name="from_email" type="email" v-model="newIntegration.options.from_email" label="From Email"
                validate="required" />
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-md-12 d-flex justify-content-center">
              <button class="btn btn-primary mr-2" :disabled="processing || invalid">
                <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF" />
                <span v-else>Update</span>
              </button>
              <button type="button" @click="closeSendgridModal()" class="btn btn-secondary">
                Cancel
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </vuestic-modal>
  </div>
</template>
  
<script>
export default {
  components: {
  },

  mounted() {
    this.initData();
  },

  computed: {
    user() {
      return this.$store.getters['auth/user']
    },

    agency() {
      return this.user && this.user.agency
    },

    sendgridConfig() {
      return this.integrations.find(item => item.key === 'sendgrid')
    },

    hasSendgridConfig() {
      return this.sendgridConfig && this.sendgridConfig.api_key
    }
  },

  data() {
    return {
      loading: false,
      processing: false,
      integrations: [],
      newIntegration: null,
      isSendgridModalOpen: false,
      isOpenModalDelete: false,
      typeSelected: null,
    };
  },

  methods: {
    initData() {
      this.loadIntegrations()
    },

    loadIntegrations() {
      this.loading = true;

      this.$store
        .dispatch("agency/getIntegrations")
        .then((res) => {
          this.integrations = res.data
          this.loading = false
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    onDeleteSendgridModal() {
      this.typeSelected = 'sendgrid'
      this.isOpenModalDelete = true
    },

    onOpenSendgridModal() {
      this.newIntegration = {
        options: {}
      }
      this.isSendgridModalOpen = true
    },

    closeSendgridModal() {
      this.isSendgridModalOpen = false
    },

    onSendgridUpdate() {
      this.processing = true

      const param = {
        ...this.newIntegration,
        type: 'sendgrid',
      }

      this.$store
        .dispatch("agency/updateIntegration", param)
        .then(() => {
          this.processing = false
          this.isSendgridModalOpen = false
          this.initData()
        })
        .catch((err) => {
          this.processing = false
        });
    },

    handleDelete() {
      this.processing = true

      this.$store
        .dispatch("agency/deleteIntegration", this.typeSelected)
        .then(() => {
          this.processing = false
          this.isOpenModalDelete = false
          this.initData()
        })
        .catch((err) => {
          this.processing = false
        });
    }
  }
};
</script>
  
<style lang="scss" scoped></style>
  